<template>
  <div class="tableList">
    <el-table :data="tableDatas">
      <el-table-column prop="payFirstTime" label="成交时间" width="100">
     
      </el-table-column>
      <el-table-column prop="orderId" label="订单号"></el-table-column>
      <el-table-column prop="value" label="商品分类"></el-table-column>
      <el-table-column prop="weight" label="重量">
        
      </el-table-column>
      <el-table-column prop="counterpartyEnterpriseName" label="交易商"></el-table-column>
      <el-table-column prop="price" label="成交单价">
          <!-- <template slot-scope="scope">
          <span>{{ scope.row.price }}元/{{ scope.row.unit ? scope.row.unit :'--'}}</span>
        </template> -->
      </el-table-column>
      <el-table-column prop="zjPrice" label="成交总价(元)"></el-table-column>
      <el-table-column prop label="操作" width="180">
        <template slot-scope="scope">
          <el-button @click="viewContract(scope.row)" size="mini">查看</el-button>
          <el-button @click="download(scope.row)" type="danger" size="mini">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    tableDatas: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  methods: {
    viewContract(row) {
      protocolFwd.param_findOneContract.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_findOneContract.param.contractId = row.contractId;
      http
        .postFront(protocolFwd.param_findOneContract)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            window.open(value);
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    download(row) {
      protocolFwd.param_downloadContract.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_downloadContract.param.documentId = row.documentId;
      protocolFwd.param_downloadContract.param.value = row.value;
      http
        .postReport(protocolFwd.param_downloadContract)
        .then(response => {
          var blob = new Blob([response.data], {
            type:
              'application/pdf'
          }) 
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '合同';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        });
    }
  }
}
</script>
<style scoped lang="scss">
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
</style>